<template>
  <div class="ori-policy">
    <ori-header />

    <match-filter />

    <div class="policy-wrap">
      <div class="policy-content w1200">
        <div class="left">
          <div class="policy-box" v-for="i in list" :key="i.id">
            <div class="img">
              <img :src="i.poster" alt="" srcset="" />
            </div>
            <div class="info">
              <div class="title-share">
                <span>{{ i.title }}</span>
                <span>
                  <i class="el-icon-share"></i>
                </span>
              </div>
              <div class="desc">
                {{ i.desc }}
              </div>
              <div class="date-count">
                <span>{{ i.date }}</span>
                <span>阅读量：{{ i.count }}</span>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="pagination-wrap">
            <ori-pagination />
          </div>
        </div>
        <div class="right">
          <div class="head">政策申报</div>
          <form class="form">
            <div class="form-item">
              <label>公司名称：</label>
              <input type="text" placeholder="请输入..." />
            </div>
            <div class="form-item">
              <label>联系人：</label>
              <input type="text" placeholder="请输入..." />
            </div>
            <div class="form-item">
              <label>手机号码：</label>
              <input type="text" placeholder="请输入..." />
            </div>
            <div class="form-item">
              <label class="block">选择需求：</label>
              <div class="options">
                <span
                  class="option"
                  v-for="i in options"
                  :key="i.id"
                  :class="{ active: i.active }"
                  @click="i.active = !i.active"
                  >{{ i.label }}</span
                >
              </div>
            </div>
            <div class="form-item">
              <label class="block">具体描述：</label>
              <textarea placeholder="请输入..." maxlength="300" />
            </div>
            <div class="form-item submit-wrap">
              <el-button type="primary" size="medium">提交</el-button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <page-foot />
  </div>
</template>

<script>
import { policyData } from "@/Mock";
import PageFoot from "../../../components/ori-com/PageFoot.vue";
import MatchFilter from "../../../components/ori-com/Select/MatchFilter.vue";
export default {
  components: { PageFoot, MatchFilter },
  name: "ori-policy",

  data() {
    return {
      list: policyData.list,
      options: [
        {
          id: 0,
          active: false,
          label: "税务筹划服务",
        },
        {
          id: 1,
          active: false,
          label: "股权融资服务",
        },
        {
          id: 2,
          active: false,
          label: "货款融资服务",
        },
        {
          id: 3,
          active: false,
          label: "高企认证服务",
        },

        {
          id: 4,
          active: false,
          label: "政府扶持资金",
        },
        {
          id: 5,
          active: false,
          label: "产学研合作",
        },
        {
          id: 6,
          active: false,
          label: "知识产权服务",
        },
        {
          id: 7,
          active: false,
          label: "财务审计服务",
        },
        {
          id: 8,
          active: false,
          label: "服务商招募",
        },
        {
          id: 9,
          active: false,
          label: "辅助账系统",
        },
        {
          id: 10,
          active: false,
          label: "总裁特训",
        },
        {
          id: 11,
          active: false,
          label: "其他服务",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.ori-policy {
  width: 100%;
  overflow: hidden;
  background-color: #f9f9f9;
  .policy-wrap {
    margin-top: 12px;
    width: 100%;
    overflow: hidden;
    .policy-content {
      display: flex;
      align-items: flex-start;

      .left {
        flex: 1 1 auto;

        .policy-box {
          cursor: pointer;
          background: #fff;
          margin-bottom: 19px;
          padding: 22px;

          display: flex;
          align-items: flex-start;

          &:active,
          &:hover {
            box-shadow: 0 0 15px #ddd;
            .img img {
              transform: scale(1.2);
            }
          }

          .img {
            flex: 0 0 auto;
            width: 180px;
            height: 120px;
            overflow: hidden;
            margin-right: 23px;
            img {
              transition: all 0.8s ease-in;
            }
          }

          .info {
            flex: 1 1 auto;
            .title-share {
              position: relative;
              span:nth-child(1) {
                font-size: 15px;
                color: #333;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 540px;
              }
              span:nth-child(2) {
                position: absolute;
                right: 0;
                color: #999;
              }
            }

            .desc {
              height: 68px;
              line-height: 23px;
              margin-bottom: 8px;
              color: #666;
              font-size: 12px;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: -o-ellipsis-lastline;
              word-break: break-all;
            }

            .date-count {
              display: flex;
              justify-content: space-between;
              color: #999;
              font-size: 12px;
            }
          }
        }

        .pagination-wrap {
          display: flex;
          justify-content: center;
          margin: 40px 0;
        }
      }
      .right {
        flex: 0 0 auto;
        width: 328px;
        margin-left: 15px;

        .head {
          margin-bottom: 10px;
          width: 100%;
          height: 44px;
          line-height: 44px;
          font-weight: bold;
          font-size: 14px;
          padding-left: 17px;
          background: #fff;
        }

        .form {
          padding: 15px 14px 38px 14px;
          background: #fff;

          .form-item {
            margin-bottom: 10px;
            label {
              height: 35px;
              line-height: 35px;
              display: inline-block;
              font-size: 14px;
              color: #666;
              width: 70px;
              margin-right: 10px;
              text-align: left;
              &.block {
                display: block;
              }
            }
            input {
              outline: 0;
              border: 0;
              width: 210px;
              padding-left: 10px;
              height: 35px;
              line-height: 35px;
              border-bottom: 1px solid #eee;
              font-size: 14px;
            }

            .option {
              font-size: 12px;
              border-radius: 3px;
              cursor: pointer;
              margin-right: 11px;
              margin-bottom: 11px;
              width: 90px;
              height: 36px;
              text-align: center;
              line-height: 36px;
              background: #eee;
              display: inline-block;

              &:nth-child(3n) {
                margin-right: 0;
              }

              &:hover,
              &:active,
              &.active {
                background: #e62d31;
                color: #fff;
              }
            }

            textarea {
              margin-top: 11px;
              width: 298px;
              height: 150px;
              border: 1px solid #eee;
              border-radius: 3px;
              overflow-y: auto;
              padding: 10px;
              outline: 0;
            }
          }

          .submit-wrap {
            text-align: center;
            ::v-deep .el-button {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
</style>